// Base
.page-header {
    border-bottom: 10px solid $primary;
}

hr {
    border: 10px solid #e3e3e3;
}

// Nav

.navbar-light .navbar-nav .active-nav-item > .nav-link {
    color: rgba(0, 0, 0, 0.7);
}

// SVG Icon Styles
.icon {
    width: 18px;
    height: 18px;
    vertical-align: sub;
    fill: currentColor;
}

// Footer
.site-footer {
    background-color: #c6c6c6;
    border-top: 1px solid $gray-200;
    margin-top: 2rem;
    padding: 1rem 0;
}
  
.footer-item {
    margin: 0.5rem;
    white-space: nowrap;
}

h1 small {
    font-size: 65%;
    line-height: 1;
    color: #777;
}

// Home
.carousel-item img {
    height: auto;
}

.logo {
    position: absolute;
    top: 0;
    left: 0;
}

.fast-info {
    background-color: #6c757d;
    padding: 3rem 0.5rem;

    .h4 {
        color: $primary;
    }

    address,
    table {
        color: white;
    }
}

a.badge {
    padding: 0.5rem 1rem;
    font-size: large;
}

h1,
h2,
h3 {
    text-transform: uppercase;
}

h2,
h3 {
    color: $primary;
    border-bottom: 10px solid $secondary;
}

h1::after,
.leistungen li::before,
.leistung::before {
    content: '◆';
}

.leistungen li::before {
    color: $primary;
}

h1::after,
.leistung::before {
    color: $secondary;
}

.leistungen li::before,
.leistung::before {
    margin-right: 0.5rem;
}

h1::after {
    vertical-align: 7.5%;
}

// Team
.team-box {
    border-left: 5px solid $primary;
    padding-left: 1rem;
}

// Gallery
.gallery {
    display: grid;
    grid-gap: 2px;
  
    @include media-breakpoint-only(xs) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-only(sm) {
        grid-template-columns: repeat(3, 1fr);
    }
    @include media-breakpoint-only(md) {
        grid-template-columns: repeat(4, 1fr);
    }
    @include media-breakpoint-only(lg) {
        grid-template-columns: repeat(5, 1fr);
    }
    @include media-breakpoint-only(xl) {
        grid-template-columns: repeat(6, 1fr);
    }
}

// Partner
.partner {
    display: grid;
    grid-gap: 1rem;
    
    @include media-breakpoint-only(sm) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include media-breakpoint-only(md) {
      grid-template-columns: repeat(4, 1fr);
    }
    @include media-breakpoint-only(lg) {
      grid-template-columns: repeat(5, 1fr);
    }
    @include media-breakpoint-only(xl) {
      grid-template-columns: repeat(6, 1fr);
    }
}

// Lazy
.lazy {
    opacity: 0;
    will-change: opacity;
    
    // Zero height divs are not loaded by Intersection Observer
    // in Chromium 72+ any more
    min-height: 1px;
}

.loaded {
    animation: fadeIn 0.5s ease-in;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    to {
      opacity: 1;
    }
}

// Misc
.triangle-topright {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 100px solid black;
    border-left: 100px solid transparent;
}
